import React from "react";
import { withPrefix } from "gatsby";

// components
import Icons from "../Icons/Index";

interface Props {
  section?: "default" | "muted" | "primary" | "secondary";
  sectionId?: string | undefined;
  iconType?: "gas" | "cold" | "temp" | "water";
  iconSize?: "small" | "medium";
  tabId?: string | null;
  tabHeading?: string | null;
  heading?: string | null;
  divider?: boolean | undefined;
  content?: string | null;
  buttonUrl?: string | undefined;
  buttonValue?: string | undefined;
  buttonTarget?: "_self" | "_blank";
  buttonRel?: string | undefined;
  removePadding?: boolean | null;
  data?: any | undefined;
}

const defaultProps: Props = {
  iconType: "cold",
  section: "default",
  heading: "Applications",
  divider: false,
  buttonValue: "Find out more",
  removePadding: false,
};

const FeatureApplication: React.FC<Props> = (props) => {
  const {
    section,
    sectionId,
    heading,
    divider,
    content,
    buttonUrl,
    buttonValue,
    buttonTarget,
    buttonRel,
    removePadding,
    data,
  } = props;

  const anchorAttr = {
    href: withPrefix(buttonUrl || ""),
    className: "uk-button uk-button-primary",
    target: buttonTarget,
    rel: buttonRel,
  };

  return (
    <section
      className={`c-feature-application uk-section c-noprint ${
        removePadding ? "uk-section-small" : "uk-section-large"
      } uk-section-${section}`}
    >
      <div className="uk-container">
        {heading && (
          <h2 className="c-feature-application__heading uk-h5 uk-h4@m uk-h3@l uk-h2@xl">
            {heading}
          </h2>
        )}
        {divider && <hr className="uk-divider-small" />}
        <div
          uk-grid=""
          className={`uk-child-width-1-2@m uk-grid-collapse uk-margin-large c-feature-application__items-${
            data && data.length
          }`}
        >
          <div className="c-feature-application__list">
            <ul
              className="uk-tab-left uk-flex-column uk-flex-left uk-height-1-1"
              uk-tab={`connect: #js-component-tab-left-${sectionId}; animation: false, uk-animation-slide-right-medium`}
              role="tablist"
            >
              {data &&
                data.map((item: any, index: number) => {
                  return (
                    <FeatureApplicationTab
                      key={`content_item_${index}`}
                      tabId={item.tabId}
                      tabHeading={item.tabHeading}
                      iconType={item.iconType}
                      iconSize={item.iconSize}
                      content={item.content}
                    />
                  );
                })}
            </ul>
          </div>
          <div className="c-feature-application__content c-feature-application__content uk-width-expand@m uk-visible@m">
            <div
              id={`js-component-tab-left-${sectionId}`}
              className="uk-switcher uk-height-1-1"
            >
              {data &&
                data.map((item: any, index: number) => {
                  return (
                    <FeatureApplicationContent
                      key={`content_item_${index}`}
                      tabId={item.tabId}
                      content={item.content}
                      iconType={item.iconType}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {content && (
          <div
            className="uk-p@s uk-width-1-1 uk-width-2-3@m uk-text-center uk-margin-auto"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
        {buttonUrl && (
          <div className="uk-width-1-1 uk-text-center">
            <div className="uk-margin-medium-top">
              <a {...anchorAttr}>
                {buttonValue}
              </a>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const FeatureApplicationContent: React.FC<Props> = (props) => {
  const { tabId, content, iconType } = props;

  return (
    <div
      id={`data-${tabId}`}
      role="tabpanel"
      className="uk-flex uk-flex-middle uk-height-1-1"
      data-bg={iconType}
    >
      {content && (
        <div
          className="c-feature-application__content-wrap uk-width-1-1"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </div>
  );
};

const FeatureApplicationTab: React.FC<Props> = (props) => {
  const { tabId, tabHeading, iconType, iconSize, content } = props;

  return (
    <li>
      <a href="#" className="uk-height-1-1" aria-controls={`data-${tabId}`}>
        <Icons iconType={iconType || "water"} iconSize={iconSize || "medium"} />{" "}
        {tabHeading}
      </a>
      {content && (
        <div
          className="uk-hidden@m c-feature-application__content"
          data-bg={iconType}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </li>
  );
};

export default FeatureApplication;

FeatureApplication.defaultProps = defaultProps;
